import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';
import { Highlight } from 'components/intro/Highlight';
import { Paragraph } from 'components/text/Paragraph';

export default () => (
  <>
    <Helmet title="About" />

    <Intro>
      Discover, <Highlight>Develop</Highlight>, Deploy.
      <Paragraph>
         A well-rounded, <Highlight>full-stack</Highlight> approach to  <strong>development</strong>. 
         Ability to write clean code, a love of interface design, and expert level knowledge.


      </Paragraph>
    </Intro>

    <Container>
      <div style={{'padding-top': '60px','padding-bottom':'400px'}}>
        <h3 style={{'fontFamily': 'industry-inc-base','fontSize':'16px','marginBottom':'10px','color':'#555555'}}>Technologies</h3>
        <Row>
          <div className="card flat">
            <span className="card__title"><Highlight>PHP 5/7</Highlight> </span><br />
            <span className="tk-ubuntu-mono">Laravel, CakePHP, CodeIgniter, MVC, OOP, Design Patterns. LAMP Stacks, PHPUnit, XDebug</span>
          </div>
          <div className="card">
            <span className="card__title">Javascript (<Highlight>ES6</Highlight>)</span><br />
            <span className="tk-ubuntu-mono">React.js, Vue.js, Angular, Gatsby.js, Knockout, Backbone. Gulp, Webpack, Grunt, jQuery, Semantic UI, AJAX</span>
          </div>
          <div className="card flat">
            <span className="card__title">Web Development</span><br />
            <span className="tk-ubuntu-mono">HTML5, Web Applications, Wireframes, Mockups, Prototyping. Mobile and Tablet. Frontend/Backend Developent.</span>
          </div>
        </Row>
        <Row>
          <div className="card flat">
            <span className="card__title">Databases</span><br />
            <span className="tk-ubuntu-mono">MySQL, PostgreSQL, DB2, Aerospike, Oracle, NoSQL, MongoDB, GraphQL, Redis</span>
          </div>
          <div className="card flat">
            <span className="card__title">APIs</span><br />
            <span className="tk-ubuntu-mono">Shopify, Taboola, Outbrain, Google, Stripe Payments, SOAP, JSON, RESTful Services, Magento</span>
          </div>
          <div className="card flat">
            <span className="card__title">CSS/SASS/LESS</span><br />
            <span className="tk-ubuntu-mono">Casscading Style-Sheets, CSS2/3, Pre-processors. Bootstrap</span>
          </div>
        </Row>   
        <Row>
          <div className="card flat">
            <span className="card__title"><Highlight>Node.js</Highlight></span><br />
            <span className="tk-ubuntu-mono">Node, ExpressJS, MEAN Stacks, Underscore.js, AXIOS, Socket.IO</span>
          </div>
          <div className="card flat">
            <span className="card__title">Amazon Web Services (AWS)</span><br />
            <span className="tk-ubuntu-mono">Amazon EC2, Amazon S3, AWS Backup, Database, Machine Learning</span>
          </div>
          <div className="card flat">
            <span className="card__title">Version Control</span><br />
            <span className="tk-ubuntu-mono">Git, Subversion, Github, Bitbucket, Beanstalk, Gitlab</span>
          </div>
        </Row>         
        <Row>
          <div className="card flat">
            <span className="card__title">UI / UX Design</span><br />
            <span className="tk-ubuntu-mono">ADobe Photoshop, Adobe Illustrator, HTML5, Interactive Mockups. PSD Splicing, Image Optimization</span>
          </div>
          <div className="card flat">
            <span className="card__title">OS Management</span><br />
            <span className="tk-ubuntu-mono">Linux, Windows/NT/2000/XP, MacOS. Docker, Docker Compose. VMware, NGINX, Apache HTTPd</span>
          </div>
          <div className="card flat">
            <span className="card__title">DevOPS</span><br />
            <span className="tk-ubuntu-mono">Jenkins, Travis CI, Bamboo. Continuous Integration. JIRA, Trello, Issue Tracking and Management</span>
          </div>
        </Row>          
      </div>
    </Container>
       
   </>
);
