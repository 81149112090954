import * as React from 'react';

import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';

import s from './Paragraph.scss';

interface IParagraphProps {
  children: React.ReactNode;
}

export const Paragraph = ({ children }: IParagraphProps) => {
  return (
      <div className={s.intro}>
            <div className={s.paragraph__text}>
              {children}
            </div>
      </div>
  );
};
